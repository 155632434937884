import React from 'react';
import './flex.scss';

type FlexContainerType = {
  grow?: number;
  shrink?: number;
  basis?: string;
  shorthand?: [];
};

export const FlexContainer: React.FC<FlexContainerType> = ({
  children,
  grow,
  shrink,
  basis,
  shorthand,
}) => {
  const shorthandString: string = shorthand?.length
    ? shorthand.join('')
    : 'inherit';
  
  return (
    <div
      className="flex-container"
      style={{
        flex: shorthandString,
        flexBasis: basis,
        flexShrink: shrink,
        flexGrow: grow,
      }}
    >
      {children}
    </div>
  );
};
