import * as React from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import Layout from '@components/layout/layout';
import { Highlight } from '@components/ui/highlight/highlight';
import { Flex } from '@components/ui/flex/flex';
import { FlexContainer } from '@components/ui/flex/flex-container';

const AboutThisSite: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ query: '(min-width: 1280px)' });
  const AboutList: [] = t('pages.about.list', { returnObjects: true });
  const AboutImages: [] = t('pages.about.images', { returnObjects: true });

  return (
    <Layout>
      <Helmet>
        <meta name="keywords" content={t('pages.about.metaKeywords')} />
        <meta name="description" content={t('pages.about.metaDescription')} />
        <title>{t('pages.about.documentTitle')}</title>
      </Helmet>
      <div className="container--fluid">
        <h1>{t('pages.about.title')}</h1>
        <img
          style={{ width: '100%' }}
          src={`../../images/${t('pages.about.heroImage')}`}
          alt={t('pages.about.heroImage')}
        />
        <h2>
          <Trans
            i18nKey="pages.about.subtitle"
            components={{ highlight: <Highlight /> }}
          />
        </h2>
        <ul>
          {AboutList.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        {isDesktop && (
          <Flex gap={30}>
            {AboutImages.map((image) => (
              <FlexContainer key={image}>
                <img alt={image} style={{ maxWidth: '100%', width: 'auto' }} src={`../../images/${image}`} />
              </FlexContainer>
            ))}
          </Flex>
        )}
        <p style={{ textAlign: 'justify' }}>
          <Trans
            i18nKey="pages.about.text"
            components={{ a: <a target="_blank" /> }}
          />
        </p>
      </div>
    </Layout>
  );
};

export default AboutThisSite;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
