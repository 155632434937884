import React from 'react';
import './flex.scss';

type FlexType = {
  variant?: string;
  gap?: number;
  margin?: string;
};

export const Flex: React.FC<FlexType> = ({
  children, variant, gap, margin,
}) => (
  <div
    className={`${variant ? `flex flex-${variant}` : 'flex'}`}
    style={{ gap, margin }}
  >
    {children}
  </div>
);
